.box {
  transition: transform .2s ease;
}
.box:hover {
  transform: scale(2.5);
  box-shadow: 0px 0px 3px #eee;
}
.box:hover:after {
    content: " ";
    position: absolute;
    left: -100vw;
    width: 200vw;
    height: 100%;
    background-color: #fff;
    opacity: 0.02;
    pointer-events: none; /* don't block other boxes  */
}